<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" max-width="900" @keypress.esc="dialog = false">
      <v-card elevation="3" :loading="loading">
        <v-toolbar dense flat>
          <v-toolbar-title>
            <v-icon>
              mdi-emoticon
            </v-icon>
            ผลการประเมิน
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="primary" @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container fluid v-if="report != null">
          <v-row>
            <!-- <v-col cols="12" md="6">
              <p class="caption mb-0">วันที่ประเมิน</p>
              <span class="subtitle-1">
                {{
                    moment(report.created_at)
                    .add(543, 'year')
                    .format('D MMMYYYY เวลา HH:mm')
                }}
              </span>
            </v-col>
            <v-col cols="12" md="6">
              <p class="caption mb-0">ชื่อผู้ประเมิน</p>
              <span class="subtitle-1">{{ report.fullname || 'ไม่ระบุ' }}</span>
            </v-col> -->
            
            
            <!-- <v-col cols="12" md="6">
              <p class="caption mb-0">ผู้ปฏิบัติ</p>
              <span class="subtitle-1">{{ report.officers.map(i => i.text).join(', ') }}</span>
            </v-col>
            <v-col cols="12" md="6">
              <p class="caption mb-0">ด่านตรวจ</p>
              <span class="subtitle-1">{{ report.checkpoint }}</span>
            </v-col>
            <v-col cols="12" md="6">
              <p class="caption mb-0">สถานี</p>
              <span class="subtitle-1">{{ report.station }}</span>
            </v-col>
            <v-col cols="12" md="12">
              <p class="caption mb-0">รายละเอียด</p>
              <span class="subtitle-1">{{ report.detail }}</span>
            </v-col>-->
          </v-row> 
          <v-row v-for="(question, index) in questions" :key="index">
            <v-col cols="12" md="8">
              <p style="color: red" class="h3 mb-2">หัวข้อคำถามข้อที่ {{ index + 1 }}</p>
              <span class="h5 mb-2">{{ question }}</span>
            </v-col>
            <v-col cols="12" md="4">
              <p style="color: blue" class="h3 mb-2">ผลการประเมิน (คะแนน)</p>
              <span class="h5 mb-2">{{ scores[index] }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { showReport } from '@/api/satisfactionreport'
export default {
  methods: {
    getReport(id) {
      this.loading = true
      showReport(id)
        .then(res => {
          this.report = Object.assign({}, res)
        })
        .finally(() => {
          this.loading = false
        })
    },
    open(item) {
      this.report = item

      this.questions = item.questions?.split('|')
      this.scores = item.scores?.split('|')
      // this.getReport(id)
      this.dialog = true
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      report: null,
      questions: [],
      scores: []
    }
  },
}
</script>

<style></style>
