<template>
  <v-dialog v-model="dialog" persistent max-width="900">
    <v-card :loading="loading">
      <v-card-title class="headline">
        <v-icon>
          mdi-handcuffs
        </v-icon>
        รายงานผลการปฏิบัติ
        <span class="subtitle-2"> {{ type == 'add' ? '' : '(แก้ไข)' }}</span>
      </v-card-title>
      <v-container fluid>
        <v-form v-model="valid" ref="form" lazy-validation>
          <!-- จุดตรวจ -->
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                label="จุดตรวจ"
                hint="เลือกจุดตรวจที่จะรายงานผล"
                @change="cChanged"
                v-model="arrestreport.checkpoint_id"
                :items="checkpoints"
                :rules="reqRule"
                required
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                label="ผู้ปฏิบัติ"
                hint="เลือกเจ้าหน้าที่ผู้ปฏิบัติ"
                multiple
                small-chips
                v-model="arrestreport.officers"
                :items="officers"
                :rules="[v => v.length > 0 || 'กรุณากรอกข้อมูล']"
                :disabled="officers.length == 0"
                required
              ></v-autocomplete>
            </v-col>
          </v-row>

          <!-- Date time -->
          <v-row>
            <v-col cols="12" md="6">
              <v-menu
                v-model="datemenu"
                ref="datemenu"
                :nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormat"
                    :rules="reqRule"
                    label="วันที่ดำเนินการ"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="arrestreport.arrest_at" no-title>
                  <v-spacer></v-spacer>
                  <v-btn @click="datemenu = false" text>ตกลง</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <!-- time -->
              <v-text-field-integer
                v-model="arrestreport.time"
                label="เวลา"
                :properties="{
                  prefix: '',
                  suffix: '',
                  rules: timeRules,
                  readonly: false,
                  disabled: false,
                  outlined: false,
                  clearable: true,
                  placeholder: '00:00',
                }"
                :options="{
                  inputMask: '##:##',
                  outputMask: '##:##',
                  empty: '',
                  applyAfter: false,
                  alphanumeric: true,
                  lowerCase: false,
                }"
              />
            </v-col>
          </v-row>

          <!-- ข้อหา การดำเนินการ -->

          <v-row>
            <v-col cols="12" md="4">
              <v-select
                label="พรบ"
                hint="เลือก พรบ"
                multiple
                small-chips
                v-model="arrestreport.types"
                @change="ctChange"
                :items="chargetype"
                :disabled="chargetype.length == 0"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                label="ข้อหา"
                hint="เลือกข้อหา (เลิือกได้หลายข้อหา)"
                multiple
                small-chips
                v-model="arrestreport.charges"
                :items="charges"
                :rules="[v => v.length > 0 || 'กรุณากรอกข้อมูล']"
                :disabled="charges.length == 0"
                required
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                label="การดำเนินการ"
                hint="การปฏิบัติ"
                v-model="arrestreport.arrest_type_id"
                :items="arresttypes"
                :rules="reqRule"
                required
              ></v-select>
            </v-col>
          </v-row>

          <v-btn @click="genDetail" color="indigo darken-4" dark small>สร้างข้อความ</v-btn>
          <!-- text -->
          <v-row>
            <v-col>
              <v-textarea label="ข้อความรายงาน" auto-grow v-model="arrestreport.detail" :rules="reqRule"></v-textarea>
            </v-col>
          </v-row>

          <!-- image -->
          <v-row>
            <v-col cols="12">
              <!-- image gallery edit-->
              <v-row v-if="type == 'edit'">
                <v-col v-for="(im, i) in arrestreport.imagesWithId" :key="i" class="d-flex child-flex" cols="3">
                  <v-card>
                    <v-card-text>
                      <v-fab-transition>
                        <v-btn fab small color="red" absolute top right dark @click="deleteImage(im.id)">
                          <v-icon>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-fab-transition>
                      <v-img :src="im.data" :lazy-src="im.data" aspect-ratio="1" class="grey lighten-2">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!-- end image gallery edit -->

              <!-- image gallery -->
              <v-row>
                <v-col v-for="(im, i) in arrestreport.images" :key="i" class="d-flex child-flex" cols="3">
                  <v-img :src="im" :lazy-src="im" aspect-ratio="1" class="grey lighten-2">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
              <!-- end image gallery -->

              <v-file-input
                label="เลือกไฟล์รูป"
                v-model="file"
                multiple
                show-size
                @change="handleFileChange"
                accept="image/png, image/jpeg, image/bmp"
                prepend-icon="mdi-camera"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn text @click="cancel">ยกเลิก</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-3" @click="submit" dark>
          <v-icon>
            mdi-content-save
          </v-icon>
          บันทึกข้อมูล
        </v-btn>
      </v-card-actions>
    </v-card>
    <bjp-confirm-dialog ref="confirm"></bjp-confirm-dialog>
  </v-dialog>
</template>

<script>
import { show, store, update } from '@/api/arrestreport'
import { destroy as destroyImage } from '@/api/picture'
import { getCharge, getChargeType } from '@/api/charge'
import { havePast } from '@/api/checkpoint'
import { byCheckpoint } from '@/api/officer'
export default {
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        if (this.type == 'add') {
          // console.log(this.arrestreport)
          // Add

          store(this.arrestreport)
            .then(res => {
              this.$toast.success({
                title: 'Success',
                message: 'เพิ่มข้อมูลเรียบร้อยแล้ว',
              })
              this.$emit('inserted')
              this.cancel()
            })
            .catch(err => {
              console.log(err)
              this.$toast.error({
                title: 'Error',
                message: 'เกิดข้อผิดพลาดระหว่างทำรายการ',
              })
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          // Edit
          update(this.arrestreport)
            .then(res => {
              if (res.success) {
                this.$toast.success({
                  title: 'Success',
                  message: 'บันทึกข้อมูลเรียบร้อยแล้ว',
                })
                this.cancel
                this.$emit('inserted')
              }
            })
            .catch(err => {
              this.$toast.error({
                title: 'Error',
                message: 'เกิดข้อผิดพลาดระหว่างทำรายการ',
              })
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
    },
    clear() {
      this.$refs.form.reset()
    },
    async init(id, type) {
      if (type == 'add') {
        this.type = 'add'
        if (this.$refs.form != undefined) this.$refs.form.resetValidation()
        this.arrestreport = Object.assign({}, this.arrestreport_init)
        this.arrestreport.time = this.moment().format('HH:mm')
      } else {
        this.type = 'edit'
        this.arrestreport = Object.assign({}, await show(id))
        this.arrestreport = { images: [], ...this.arrestreport }
        this.cChanged()
        this.ctChange()
      }
    },
    cancel() {
      this.arrestreport = Object.assign({}, this.arrestreport_init)
      this.$refs.form.resetValidation()
      this.file = null
      this.dialog = false
    },
    async deleteImage(id) {
      const confirm = await this.$refs.confirm.open('ลบรูปภาพ', 'ต้องการลบรูปภาพนี้ ?', { color: 'red' })
      if (confirm) {
        destroyImage(id).then(res => {
          const index = this.arrestreport.imagesWithId.findIndex(i => i.id == id)
          this.arrestreport.imagesWithId.splice(index, 1)
        })
      }
    },
    // utils
    handleFileChange(files) {
      if (!Array.isArray(files) || files.length == 0) {
        this.arrestreport.images = []
        return
      }

      files.forEach(file => {
        let reader = new FileReader()
        reader.onload = e => {
          // console.log(e.target.result)
          this.arrestreport.images.push(e.target.result)
        }

        reader.onabort = () => {
          this.readerCompleted = true
        }
        reader.onloadend = () => {
          reader.abort()
        }
        reader.readAsDataURL(file)
      })
    },
    async cChanged() {
      if (this.type == 'add') {
        const cp = this.checkpoints.find(c => c.value == this.arrestreport.checkpoint_id)

        this.arrestreport.arrest_at = cp.date
      }
      this.officers = await byCheckpoint(this.arrestreport.checkpoint_id)
    },
    async ctChange() {
      this.charges = await getCharge({ type: this.arrestreport.types })
    },
    date_format(d) {
      return (
        this.moment(d)
          .add(543, 'year')
          .format('D MMMYYYY') || null
      )
    },
    // gen detail text
    genDetail() {
      if (
        this.arrestreport.officers.length == 0 ||
        this.arrestreport.charges.length == 0 ||
        this.arrestreport.arrest_type_id == null
      )
        return
      let txt = this.detail_init
      const offs = this.officers
      txt = txt.replace('#date', this.dateFormat)
      txt = txt.replace('#time', this.arrestreport.time)
      txt = txt.replace('#type', this.arresttypes.filter(o => o.value == this.arrestreport.arrest_type_id)[0].text)

      const o = []
      this.arrestreport.officers.forEach(v => {
        const tmp = offs.filter(o => o.value == v)
        o.push(tmp[0].text)
      })
      txt = txt.replace('#officers', o.join(', '))

      const c = []
      this.arrestreport.charges.forEach(v => {
        c.push(this.charges.filter(c => c.value == v)[0].text)
      })
      txt = txt.replace('#charges', c.join(', '))

      console.log(txt)

      this.arrestreport.detail = txt
    },
  },
  data: () => ({
    valid: true,
    loading: false,
    levels: [],
    type: 'add',
    datemenu: false,
    bdatemenu: false,
    readerCompleted: false,
    file: null,
    checkpoints: [],
    officers: [],
    arresttypes: [
      { value: 1, text: 'จับกุม' },
      { value: 2, text: 'ว่ากล่าวตักเตือน' },
      { value: 3, text: 'รายงานผลการปฏิบัติ' },
    ],
    charges: [],
    chargetype: [],
    arrestreport_init: {
      id: null,
      charges: [],
      officers: [],
      detail: '',
      arrest_at: new Date().toISOString().substring(0, 10),
      time: '',
      checkpoint_id: '',
      images: [],
      arrest_type_id: null,
      types: [1],
    },
    detail_init:
      'เรียนผู้บังคับบัญชา เพื่อโปรดทราบ\r\n #date เวลา #time #officers ได้ทำการ #type (ผู้ต้องหา) เนื่องมาจาก #charges ',
    arrestreport: {},
    reqRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล'],
    cidRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล', v => /\d{13}/.test(v) || 'กรุณากรอกเป็นตัวเลขเท่านั้น'],
    phoneRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล', v => /\d{9,10}/.test(v) || 'กรุณากรอกเป็นตัวเลขเท่านั้น'],
    timeRules: [v => /^((?:[01]\d:[0-5][0-9]|2[0-3]:[0-5][0-9])(?:\s?))$/.test(v) || 'กรอกเวลาให้ถูกต้อง'],
  }),
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    dateFormat() {
      return this.date_format(this.arrestreport.arrest_at)
    },
    bdateFormat() {
      return this.date_format(this.arrestreport.birthdate)
    },
  },
  watch: {
    bdatemenu(val) {
      val && setTimeout(() => (this.$refs.bdatePicker.activePicker = 'YEAR'))
    },
  },
  async created() {
    this.checkpoints = await havePast()
    this.charges = await getCharge()
    this.chargetype = await getChargeType()
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
}
</script>

<style></style>
